import styled from "styled-components";

export const NearbyTheatreContainer = styled.div`
  border-radius: 40px;
  background-color: rgba(69, 27, 187, 0.8);
  padding: 20px 30px 20px 40px;
  text-align: right;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10%;
  color: #fff;

  .title {
    font-size: 40px;
    line-height: 62px;
    font-family: "Noto Serif", serif;
    font-weight: 500;
  }

  .subtitle {
    font-size: 28px;
    line-height: 40px;
    font-family: "Noto Serif", serif;
  }

  .findButton {
    background-color: #ffb80c;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    border-radius: 40px;
    padding: 16px 15px;
    border: none;
    display: inline-flex;
    margin-top: 20px;
    outline: none;
    cursor: pointer;
    box-shadow: inset 0px -8px 0px rgb(177 100 0 / 30%);
    text-decoration: underline;
  }

  @media (max-width: 1020px) {
    text-align: center;
    right: 50%;
    width: 90%;
    transform: translate(50%, -50%);
    padding: 20px;

    .findButton {
      display: none;
    }

    .title {
      font-weight: bold;
      font-size: 28px;
      line-height: 48px;
    }

    .subtitle {
      font-size: 20px;
      line-height: 28px;
      font-family: "Noto Serif", serif;
    }

    .findButton_mobile {
      display: block;
    }
  }
`;
