import styled from "styled-components";

export const OurBranchesContainer = styled.div`
  padding: 30px 200px;
  background: #f9ecff;

  .heading {
    font-size: 32px;
    font-weight: bold;
  }

  .cards_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0px;
    column-gap: 20px;
    justify-items: center;
    align-items: center;

    @media (max-width: 1020px) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 1280px) {
    padding: 30px 100px;
  }

  @media (max-width: 1020px) {
    grid-template-columns: 1fr;
    padding: 0px;

    .heading {
      font-size: 24px;
      text-align: center;
      padding-top: 20px;
    }
  }
`;
