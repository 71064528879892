import * as S from "./AboutUs.style";
import React from "react";

const AboutUs = ({ cityJson }: any) => {
  const handleBookNow = () => {
    //navigate("private-theaters-booking");
    const element = document.getElementById("our-branches");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the section
    }
  };

  return (
    <>
      <S.AboutUsContainer>
        <S.LeftHalf>
          <React.Fragment key={`why-fav`}>
            <S.Heading>
              Why The Binge Town is {cityJson.name}'s Favorite?
            </S.Heading>
            <S.List>
              <S.ListItem>
                Luxurious private theaters in multiple locations.
              </S.ListItem>
              <S.ListItem>Sony Surround sound with 4k projector.</S.ListItem>
              <S.ListItem>Customizable themes for every occasion.</S.ListItem>
              <S.ListItem>
                Afforadable packages with premium amenities.
              </S.ListItem>
            </S.List>
            <S.BookNowBtnContainer>
              <S.BookNowBtn onClick={handleBookNow}>Book Now</S.BookNowBtn>
            </S.BookNowBtnContainer>
          </React.Fragment>
        </S.LeftHalf>

        <S.RightHalf>
          <S.ImgContainer key={`image`}>
            <img
              loading="lazy"
              src={
                "https://storage.googleapis.com/tbt-pics/images/newWeb/whychooseus/whychooseus_1.jpg"
              }
              alt={"why_us"}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "12px",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          </S.ImgContainer>
        </S.RightHalf>
      </S.AboutUsContainer>
      <S.MwebAboutUsContainer>
        <S.Heading>Why The Binge Town is {cityJson.name}'s Favorite?</S.Heading>
        <S.CarouselSection key={`carousel_mobile}`}>
          <S.MwebImgContainer>
            <img
              loading="lazy"
              src={
                "https://storage.googleapis.com/tbt-pics/images/newWeb/whychooseus/whychooseus_1.jpg"
              }
              alt={"why_us"}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "12px",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          </S.MwebImgContainer>
          <S.List>
            <S.ListItem>
              Luxurious private theaters in multiple locations.
            </S.ListItem>
            <S.ListItem>Sony Surround sound with 4k projector.</S.ListItem>
            <S.ListItem>Customizable themes for every occasion.</S.ListItem>
            <S.ListItem>
              Afforadable packages with premium amenities .
            </S.ListItem>
          </S.List>
        </S.CarouselSection>
        <S.BookNowBtnContainer>
          <S.BookNowBtn onClick={handleBookNow}>Book Now</S.BookNowBtn>
        </S.BookNowBtnContainer>
      </S.MwebAboutUsContainer>
    </>
  );
};
export default AboutUs;
