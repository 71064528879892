import styled from "styled-components";

// Styled Components
export const StyledCard = styled.div`
  background-color: #ffffff; /* White background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px; /* Padding inside the card */
  text-align: left; /* Left-align text */
  max-width: 400px;
  color: #fff;
  display: flex;
  height: 100%;
  gap: 20px;

  @media (max-width: 1020px) {
    max-width: 100%;
  }
`;

export const StyledIcon = styled.div`
  font-size: 32px; /* Size of the icon */
  margin-bottom: 10px; /* Space below the icon */
`;

export const StyledTitle = styled.h3`
  color: #8e44ad;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px 0;
`;

export const StyledDescription = styled.p`
  color: #333333; /* Dark gray for description */
  font-size: 16px; /* Font size */
  line-height: 1.5; /* Line height for readability */
  margin: 0; /* Reset margin */
  font-weight: 600;
`;
