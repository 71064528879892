import Birthday from "../../../../../../assets/images/Occasion/Birthday.webp";
import Anniversary from "../../../../../../assets/images/Occasion/Anniversary.webp";
import Romantic_date from "../../../../../../assets/images/Occasion/Romantic_date.webp";
import Marriage_proposal from "../../../../../../assets/images/Occasion/Marriage_proposal.webp";
import Congratulations from "../../../../../../assets/images/Occasion/Congratulations.webp";
import Baby_shower from "../../../../../../assets/images/Occasion/Baby_shower.webp";
import Farewell from "../../../../../../assets/images/Occasion/Farewell.webp";
import Bride_to_be from "../../../../../../assets/images/Occasion/Bride_to_be.webp";

import Birthday_selected from "../../../../../../assets/images/Occasion/SelectedState/Birthday_selected.webp";
import Anniversary_selected from "../../../../../../assets/images/Occasion/SelectedState/Anniversary_selected.webp";
import Baby_shower_selected from "../../../../../../assets/images/Occasion/SelectedState/Baby_shower_selected.webp";
import Bride_to_be_selected from "../../../../../../assets/images/Occasion/SelectedState/Bride_to_be_selected.webp";
import Congratulations_selected from "../../../../../../assets/images/Occasion/SelectedState/Congratulations_selected.webp";
import Farewell_selected from "../../../../../../assets/images/Occasion/SelectedState/Farewell_selected.webp";
import Marriage_proposal_selected from "../../../../../../assets/images/Occasion/SelectedState/Marriage_proposal_selected.webp";
import Romantic_date_selected from "../../../../../../assets/images/Occasion/SelectedState/Romantic_date_selected.webp";

export const OCCASIONS = {
  Birthday: {
    id: 1,
    title: "Birthday",
    imgUrl: Birthday,
    selectedImgUrl: Birthday_selected,
  },
  Anniversary: {
    id: 2,
    title: "Anniversary",
    imgUrl: Anniversary,
    selectedImgUrl: Anniversary_selected,
  },
  "Romantic Date": {
    id: 3,
    title: "Romantic Date",
    imgUrl: Romantic_date,
    selectedImgUrl: Romantic_date_selected,
  },
  "Marriage Proposal": {
    id: 4,
    title: "Marriage Proposal",
    imgUrl: Marriage_proposal,
    selectedImgUrl: Marriage_proposal_selected,
  },
  Farewell: {
    id: 5,
    title: "Farewell",
    imgUrl: Farewell,
    selectedImgUrl: Farewell_selected,
  },
  "Bride to be": {
    id: 5,
    title: "Bride to be",
    imgUrl: Bride_to_be,
    selectedImgUrl: Bride_to_be_selected,
  },
  Congratulations: {
    id: 6,
    title: "Congratulations",
    imgUrl: Congratulations,
    selectedImgUrl: Congratulations_selected,
  },
  "Baby Shower": {
    id: 7,
    title: "Baby Shower",
    imgUrl: Baby_shower,
    selectedImgUrl: Baby_shower_selected,
  },
  "Groom To Be": {
    id: 8,
    title: "Groom to be",
    imgUrl: Farewell,
    selectedImgUrl: Farewell_selected,
  },
};
