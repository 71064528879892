import { useSelector } from "react-redux";
import * as S from "./Gallery.style";
import { getGalleryImages } from "../../../../../redux/selectors/webConfigSelector";
import Himayatnagar from "../../../../../assets/images/hyderabad/Himayatnagar.jpg";
import Miyapur from "../../../../../assets/images/hyderabad/Miyapur.jpg";

const GalleryPage = () => {
  const galleryImages = useSelector(getGalleryImages) || {};
  const { web = [], mobile = [] } = galleryImages || {};

  const handleBookNow = () => {
    //navigate("private-theaters-booking");
    const element = document.getElementById("our-branches");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the section
    }
  };

  return (
    <>
      <S.GalleryContainer>
        <S.Heading>Gallery</S.Heading>
        <S.GridContainer>
          {web &&
            web.length > 0 &&
            [...web, Miyapur, Himayatnagar].map(
              (image: string, ind: number) => {
                return (
                  <S.Image
                    target="_blank"
                    href={image}
                    rel="noopener noreferrer"
                    key={ind}
                    title={`galleryImg_${ind}`}
                  >
                    <img
                      className="galleryImg"
                      alt="img"
                      title="img"
                      src={image}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </S.Image>
                );
              }
            )}
        </S.GridContainer>
        <S.BookNowBtnContainer>
          <S.BookNowBtn onClick={handleBookNow}>Book Now</S.BookNowBtn>
        </S.BookNowBtnContainer>
      </S.GalleryContainer>
    </>
  );
};

export default GalleryPage;
