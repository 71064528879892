import {
  StyledCard,
  StyledDescription,
  StyledIcon,
  StyledTitle,
} from "./Card.styles";

const CardComponent = ({
  icon = "🎂",
  title = "Birthday Party",
  description = "Celebrate your birthday in style with our private theatre experience. Enjoy watching your favorite movies or shows.",
}) => {
  return (
    <StyledCard>
      <StyledIcon>{icon}</StyledIcon> {/* Emoji or replace with an icon */}
      <div>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </div>
    </StyledCard>
  );
};

export default CardComponent;
