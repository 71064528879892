import { NearbyTheatreContainer } from "./FindNearbyTheatre.styles";

const FindNearbyTheatre = ({ cityJson }: any) => {
  return (
    <>
      <NearbyTheatreContainer>
        <h1 className="title">
          Celebrate in Styled at {cityJson.name}'s <br />
          Best Private Theaters
        </h1>
        <h2 className="subtitle">
          Choose from multiple branches across {cityJson.name} <br />
          for unforgettable celebrations
        </h2>
        <a href="https://thebingetown.com/" className="findButton">
          Find Your Nearest Theatre
        </a>
      </NearbyTheatreContainer>
      <a href="https://thebingetown.com/" className="findButton_mobile">
        Find Your Nearest Theatre
      </a>
    </>
  );
};

export default FindNearbyTheatre;
