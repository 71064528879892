import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "react-select";
import ButtonView from "../../../../../../components/common/ButtonView";
import PeopleCountIcon from "../../../../../../assets/images/Theaters/PeopleCountIcon.svg";
import Menu from "../../../../../../assets/images/Theaters/Menu.svg";
import TheaterInchIcon from "../../../../../../assets/images/Theaters/TheaterInchIcon.svg";
import TheaterWIcon from "../../../../../../assets/images/Theaters/TheaterWIcon.svg";
import Cake from "../../../../../../assets/images/Theaters/Cake.svg"
import FoodAndBeverages from "../../../../../../assets/images/Theaters/FoodAndBeverages.svg"
import Refund from "../../../../../../assets/images/Theaters/Refund.svg"
import SunIcon from "../../../../../../assets/images/Theaters/SunIcon.svg";
import MoonIcon from "../../../../../../assets/images/Theaters/MoonIcon.svg";
import YoutubeIcon from "../../../../../../assets/images/Theaters/YoutubeIcon.svg";
import Carousel from "react-material-ui-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MdRestaurantMenu } from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as TheaterLOcation } from "../../../../../../assets/images/Theaters/TheaterLocation.svg";
import { ReactComponent as HotBeverage } from "../../../../../../assets/images/Theaters/HotBeverage.svg";
import { ReactComponent as People } from "../../../../../../assets/images/Theaters/People.svg";
import { ReactComponent as Cancellation } from "../../../../../../assets/images/Theaters/Cancellation.svg";
import { ReactComponent as Decor } from "../../../../../../assets/images/Theaters/Decor.svg";
import { ReactComponent as Location } from "../../../../../../assets/images/Theaters/Location.svg";
import { ReactComponent as Video } from "../../../../../../assets/images/Theaters/Video.svg";
import { FaStar } from "react-icons/fa";
import { BiCurrentLocation } from "react-icons/bi";
import { ReactComponent as ChefHat } from "../../../../../../assets/images/Theaters/ChefHat.svg";
import {
  setActiveTimeSlotInd,
  setSelectedTheater,
  setSelectedTheaterTime,
  setSelectedTimeSlotInd,
  setShortSlotSelected,
} from "../../../../../../redux/actions/bookingActions";
import {
  getActiveTimeSlotInd,
  getSelectedLocation,
  getSelectedTheaterDate,
  getTheaterTimeSlots,
} from "../../../../../../redux/selectors/bookingSelector";

import * as S from "./Theaters.style";

import 'bootstrap/dist/css/bootstrap.css';
//import Zoom from 'react-medium-image-zoom';
//import 'react-medium-image-zoom/dist/styles.css';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const TimeSlot = styled.button<{ $active?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.$active ? "#E8F2F8" : 'inherit'};
  color: #34265c;
  border: 0.5px solid ${(props) => (props.$active ? '#1481BA' : '#34265c')};
border-radius: 0.5rem;
margin: 0.2rem 0.2rem;
padding: 0.1rem;
font - family: Montserrat;
flex-grow: 1;           /* Allow slots to grow and share available space */
    flex-basis: 0;          /* Start with no initial size, allowing flex-grow to control */
    box-sizing: border-box; /* Include padding in width calculation */
    text-align: center; 
`;

const DisableTimeSlot = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #34265c;
  border: 0.5px solid #34265c;
  border-radius: 0.5rem;
  margin: 0.2rem;
  padding: 0.1rem;
  opacity: 0.5; 
  flex-grow: 1;           /* Allow slots to grow and share available space */
    flex-basis: 0;          /* Start with no initial size, allowing flex-grow to control */
    box-sizing: border-box; /* Include padding in width calculation */
    text-align: center; 

  &:hover {
    cursor: not-allowed; 
  }
`;

const TimeIcon = styled.div<{ $active?: boolean }>`
  // background-color: ${(props) => (props.$active ? "#ffffff" : "#000000")};
  `;

const MwebTimeSlot = styled.div`
display: flex;
gap: 1rem;
`;

const MwebTimeSlotStrikeThrough = styled.div`
display: flex;
gap: 1rem;
text - decoration: line - through;
`;

const MWebTimeIcon = styled.div``;
const MwebTimeTxt = styled.div``;

const Theaters = ({ theaterDetails, index, otherTheaterTimeSlots }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMweb = useMediaQuery("(max-width:786px)");
  const theaterTimeSlots = useSelector(getTheaterTimeSlots);
  const selectedLocation = useSelector(getSelectedLocation);
  const selectedDate = useSelector(getSelectedTheaterDate);
  const [proceedDisable, setProceedDisable] = useState(true);
  const activeTimeSlotInd = useSelector(getActiveTimeSlotInd);
  const [googleMapLocationUrl, setGoogleMapUrl] = useState<string>(theaterDetails?.googleMapUrl);
  const [foodMenuUrl, setfoodMenuUrl] = useState<string>(theaterDetails?.menuUrl);
  const handleSelectedTimeSlot = (time: string, ind: number) => {
    if (ind === shortSlotInd) {
      dispatch(setShortSlotSelected(true));
    } else {
      dispatch(setShortSlotSelected(false));
    }
    let newActiveTimeSlotInd = [...activeTimeSlotInd];
    newActiveTimeSlotInd = Array.from({ length: 50 }, () => -1);
    newActiveTimeSlotInd[index] = ind;
    setActiveTimeSlotInd(newActiveTimeSlotInd);
    dispatch(setActiveTimeSlotInd(newActiveTimeSlotInd));
    dispatch(setSelectedTheater(theaterDetails));
    dispatch(setSelectedTheaterTime(time));
    dispatch(setSelectedTimeSlotInd(ind));
  };
  useEffect(() => {
    if (theaterDetails) {
      setGoogleMapUrl(theaterDetails?.googleMapUrl);
      setfoodMenuUrl(theaterDetails?.menuUrl);
    }
  }, [theaterDetails])

  const handleMwebSelectedTimeSlot = (e: any) => {
    let ind = timeOptions.findIndex((option: any) => option.value === e.value);
    if (!disabledTheaterTimeSlots.includes(ind)) {
      setProceedDisable(false);
    } else {
      setProceedDisable(true);
    }
    if (ind === shortSlotInd) {
      dispatch(setShortSlotSelected(true));
    } else {
      dispatch(setShortSlotSelected(false));
    }
    let newActiveTimeSlotInd = [...activeTimeSlotInd];
    newActiveTimeSlotInd = Array.from({ length: 50 }, () => -1);
    newActiveTimeSlotInd[index] = ind;
    // Update local state
    setActiveTimeSlotInd(newActiveTimeSlotInd);

    // Ensure other components have the updated state
    dispatch(setActiveTimeSlotInd(newActiveTimeSlotInd));
    dispatch(setSelectedTheaterTime(e?.value));
    dispatch(setSelectedTimeSlotInd(ind));
  };

  const handleProceed = () => {
    dispatch(setSelectedTheater(theaterDetails));

    const theaterCode = theaterDetails?.code;
    const formattedDate = `${selectedDate?.year}_${selectedDate?.month}_${selectedDate?.day}`;

    // Get the current URL
    const currentUrl = window.location.pathname;

    // Remove the `?date=...` part
    let urlWithoutDate = currentUrl.split("?")[0];

    let urlParts = currentUrl.split('/');
    urlParts = urlParts.filter(str => str != "")
    // Remove the `?date=...` part
    let encodedLoc =  encodeURIComponent(theaterDetails.location.toLowerCase()).replace(/\+/g, "%20")
    if(urlParts.length <= 1){
      console.log("url issue ", urlWithoutDate, encodedLoc);
      urlWithoutDate = urlWithoutDate + (urlWithoutDate.slice(-1) == '/')
       ? `${encodedLoc}` : `/${encodedLoc}`; 
    }

    // Navigate with the new URL path and date
    navigate(`${urlWithoutDate}/booking_overview/${theaterCode}?date=${formattedDate}`);
  };


  const [timeOptions, setTimeOptions] = useState<any>([]);

  const disabledTheaterTimeSlots = theaterTimeSlots[theaterDetails?.code] || (otherTheaterTimeSlots && otherTheaterTimeSlots[theaterDetails?.code]) || [];
  const totalTheaterTimeSlots = theaterDetails?.times?.length || 0;

  const videoSampleUrl = theaterDetails?.video || "";
  const shortSlotInd = theaterDetails?.shortSlot || -1;
  const shortSlotExist = theaterDetails?.shortSlotExist || -1;

  const { times = [] } = theaterDetails || {};
  const { year, month, day } = selectedDate || {};

  // const [disabledTheaterTimeSlots, setDisableTheaterTimeSlots] = useState<
  //   Number[]
  // >([]);
  // const [theTimeSlots, setTheaterTimeSlots] = useState<any>({});

  // useEffect(() => {
  //   setActiveTimeSlotInd(-1);
  // }, [selectedDate, selectedLocation]);

  const remainingSlots =
    totalTheaterTimeSlots - disabledTheaterTimeSlots?.length || 0;

  const getTimeIcon = (date: any) => {
    let [startTime, endTime] = date?.split?.(" - ");
    const startTimeValue = startTime?.split?.(" ")?.[0]?.split?.(":")?.[0];
    const startTimeAMPM = startTime?.split?.(" ")?.[1];
    const endTimeValue = endTime?.split?.(" ")?.[0]?.split?.(":")?.[0];
    const endTimeAMPM = endTime?.split?.(" ")?.[1];
    if (
      (startTimeValue > 6 && startTimeAMPM === "AM") ||
      (endTimeValue <= 6 && endTimeAMPM === "PM")
    ) {
      return "SunIcon";
    } else {
      return "MoonIcon";
    }
  };

  useEffect(() => {
    let options: any[] = [];
    theaterDetails &&
      theaterDetails.times.length > 0 &&
      theaterDetails.times.map((time: any, ind: number) => {
        const timeIcon = getTimeIcon(time);
        if (disabledTheaterTimeSlots.includes(ind)) {
          options.push({
            label: (
              <MwebTimeSlotStrikeThrough key={`time_strike_${ind}`}>
                <MWebTimeIcon>
                  {timeIcon === "SunIcon" ? (
                    <img src={SunIcon} alt="SunIcon" title="SunIcon" />
                  ) : (
                    <img src={MoonIcon} alt="MoonIcon" title="MoonIcon" />
                  )}
                </MWebTimeIcon>

                <MwebTimeTxt>{time}</MwebTimeTxt>
              </MwebTimeSlotStrikeThrough>
            ),
            value: time,
          });
        } else {
          options.push({
            label: (
              <MwebTimeSlot key={`time_strike_${ind}`}>
                <MWebTimeIcon>
                  {timeIcon === "SunIcon" ? (
                    <img src={SunIcon} alt="SunIcon" title="SunIcon" />
                  ) : (
                    <img src={MoonIcon} alt="MoonIcon" title="MoonIcon" />
                  )}
                </MWebTimeIcon>

                <MwebTimeTxt>{time}</MwebTimeTxt>
              </MwebTimeSlot>
            ),
            value: time,
          });
        }
      });
    setTimeOptions(options);
  }, [theaterDetails?.times, disabledTheaterTimeSlots]);

  const theaterCost = theaterDetails.isDecorationMandatory
    ? theaterDetails?.theatreCost + theaterDetails?.decorCost
    : theaterDetails?.theatreCost;

  return (
    <S.TheatersContainer>
      <S.TheaterView>
        <S.LeftView>
          <Carousel
            interval={4000}
            navButtonsWrapperProps={{
              style: {
                zIndex: 0,
              },
            }}
            indicatorContainerProps={{
              style: {
                width: "100%",
                position: "relative",
                zIndex: 1,
                marginTop: "-1.25rem",
                left: "40%",
                top: "-0.75rem",
              },
            }}
            indicatorIconButtonProps={{
              style: {
                color: "#ffffff", // 3
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: "#a6a6a6", // 2
                color: "#bfbfbf",
              },
            }}
          >
            {theaterDetails?.pics?.map((pic: any, ind: Number) => {
              return (
                <S.CarouselView key={`pic_${ind} `}>
                  <S.ViewSampleVideoContainer
                    onClick={() => window.open(videoSampleUrl)}
                  >
                    <S.ViewSampleVideoIcon>
                      <Video />
                    </S.ViewSampleVideoIcon>
                    <S.ViewSampleVideoTxt>Watch Video</S.ViewSampleVideoTxt>
                  </S.ViewSampleVideoContainer>
                  <S.RatingContainer
                    onClick={() => window.open(googleMapLocationUrl)}
                  >
                    <FaStar
                      onClick={() => window.open(googleMapLocationUrl)}
                      style={{ color: "orange" }}
                    />
                    <S.RatingText
                      onClick={() => window.open(googleMapLocationUrl)}
                    >
                      4.9
                    </S.RatingText>
                  </S.RatingContainer>
                  <S.ImageContainer>
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          key={pic.index}
                          src={pic.url}
                          alt={pic.altText}
                          title={pic.altText}
                          style={{
                            width: "100%",
                            borderRadius: "1.5rem",
                            objectFit: "cover",
                            margin: "0",
                            padding: "0",
                            pointerEvents: "none",
                          }}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </S.ImageContainer>
                </S.CarouselView>
              );
            })}
          </Carousel>
        </S.LeftView>
        <S.RightView>
          <S.TitleContainer>
            <S.TitleLocationContainer>
              <S.Title>{theaterDetails?.name}</S.Title>
              <S.LocationSlotContainer>
                <TheaterLOcation
                  onClick={() => window.open(googleMapLocationUrl)}
                  style={{ cursor: "pointer" }}
                />
                <S.LocationInfo
                  onClick={() => window.open(googleMapLocationUrl)}
                >
                  {theaterDetails.location}
                </S.LocationInfo>
                <S.MoreInfoAboutSlots>
                  {remainingSlots > 3 ? (
                    <S.SlotsAvailable>
                      {remainingSlots} Slots Available
                    </S.SlotsAvailable>
                  ) : (
                    <S.MinSlotsAvailable>
                      {remainingSlots} Slots Available
                    </S.MinSlotsAvailable>
                  )}
                </S.MoreInfoAboutSlots>
              </S.LocationSlotContainer>
            </S.TitleLocationContainer>
            <S.TitleButtonContainer>
              <S.LocationShareButton
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <BiCurrentLocation
                  style={{
                    color: "#00008b",
                    height: "1.25rem",
                    width: "1.25rem",
                  }}
                  onClick={() => window.open(googleMapLocationUrl)}
                />
                <p
                  style={{
                    margin: "0",
                    fontSize: "0.75rem",
                    color: "rgb(0, 0, 139)",
                  }}
                >
                  MAPS
                </p>
              </S.LocationShareButton>
              <S.LocationShareButton
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <MdRestaurantMenu
                  style={{
                    color: "rgb(0, 0, 139)",
                    height: "1.25rem",
                    width: "1.25rem",
                  }}
                  onClick={() => window.open(foodMenuUrl)}
                />
                <p
                  style={{
                    margin: "0",
                    fontSize: "0.75rem",
                    color: "rgb(0, 0, 139)",
                  }}
                >
                  MENU
                </p>
              </S.LocationShareButton>
           </S.TitleButtonContainer>
          </S.TitleContainer>
          <S.AllInfoContainer>
            <S.InfoContainer>
              <People />
              <S.InfoText>Max {theaterDetails?.maxPerson} People</S.InfoText>
              <HotBeverage />
              <S.InfoText>Food & Drinks available</S.InfoText>
            </S.InfoContainer>
            <S.InfoContainer>
              <Cancellation />
              <S.InfoText>Free Cancellation*</S.InfoText>
              <Decor />
              {theaterDetails.isDecorationMandatory ? (
                <S.InfoText>Decoration Included</S.InfoText>
              ) : (
                <S.InfoText>
                  Decor &#8377;{theaterDetails?.decorCost} extra
                </S.InfoText>
              )}
            </S.InfoContainer>
          </S.AllInfoContainer>
          <S.SelectTimeSlot>
            <S.TimeSlotHeading>Select Time Slot</S.TimeSlotHeading>
            <S.TimeSlotContainer>
              {times.length > 0 &&
                times.map((time: any, ind: number) => {
                  const isDisabled = disabledTheaterTimeSlots?.includes(ind);
                  const TimeSlotComponent = isDisabled
                    ? DisableTimeSlot
                    : TimeSlot;
                  const timeParts = time.split("-"); // Split the time into parts
                  const firstPart = timeParts[0]; // e.g., "12"
                  const secondPart = timeParts[1]; // Conditional component assignment

                  return (
                    <TimeSlotComponent
                      key={`time_slot_${ind} `} // Use unique keys, and avoid using index as key in some cases
                      onClick={
                        !isDisabled
                          ? () => handleSelectedTimeSlot(time, ind)
                          : undefined
                      } // Conditional click handler
                      $active={activeTimeSlotInd[index] === ind} // Use strict equality
                    >
                      <TimeIcon
                        $active={activeTimeSlotInd[index] === ind}
                      ></TimeIcon>
                      <S.TimeTxt>
                        {firstPart} <span style={{ color: "black" }}>-</span>{" "}
                        <br />
                        {secondPart}
                      </S.TimeTxt>
                    </TimeSlotComponent>
                  );
                })}
            </S.TimeSlotContainer>
          </S.SelectTimeSlot>

          <S.PriceBookButtonContainer>
            <S.PriceContainer>
              {theaterDetails?.theatreCost ? (
                <S.PriceContainerText>
                  <S.Price>&#8377;{theaterCost}</S.Price>
                  <S.TaxInclude>
                    for up to {theaterDetails?.avgPerson} people
                    {theaterDetails?.isDecorationMandatory
                      ? " with decoration"
                      : ""}
                  </S.TaxInclude>
                </S.PriceContainerText>
              ) : null}
              {theaterDetails &&
              theaterDetails?.costPerExtraPerson > 0 &&
              theaterDetails?.avgPerson != theaterDetails?.maxPerson ? (
                <S.ExtraChargePrice>
                  Additional &#8377;{theaterDetails?.costPerExtraPerson} per
                  person after {theaterDetails?.avgPerson} people
                </S.ExtraChargePrice>
              ) : (
                <S.ExtraChargePrice>
                  More than {theaterDetails?.maxPerson} people not allowed
                </S.ExtraChargePrice>
              )}
            </S.PriceContainer>
            {/* {window.innerWidth >= 768 && <S.BookButtonContainer onClick={handleProceed}>
              Pay &#8377;{theaterDetails?.advance} To Reserve
            </S.BookButtonContainer>} */}
          </S.PriceBookButtonContainer>
          {/* {theaterDetails?.costPerExtraPerson ? (
            <S.ExtraChargeContainer>
              <S.ExtraChargePrice>
                &#8377; {theaterDetails?.costPerExtraPerson}
              </S.ExtraChargePrice>
              <S.ExtraChargeText> per extra person</S.ExtraChargeText>
            </S.ExtraChargeContainer>
          ) : null} */}

          {/*<S.PeopleInfo>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="button-tooltip-1" > Maximum Theater Capacity Is {theaterDetails?.maxPerson} people </Tooltip>}
            >
              <S.MaxLimit>Max {theaterDetails?.maxPerson} people </S.MaxLimit>
            </OverlayTrigger>
            {theaterDetails?.isDecorationMandatory ? (
              <>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip-2" > Decoration charges is included in theater price </Tooltip>}
                >
                  <S.MaxLimit style={{ backgroundColor: "#DACDF4" }}>
                    Decoration Included
                  </S.MaxLimit>
                </OverlayTrigger>
              </>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip-2" > Decoration charges is &#8377;{theaterDetails?.decorCost}</Tooltip>}
              >
                <S.MaxLimit style={{ backgroundColor: "#DACDF4" }}>
                  Decoration - &#8377;{theaterDetails?.decorCost}
                </S.MaxLimit>
              </OverlayTrigger>
            )}
          </S.PeopleInfo>*/}
          {/*<S.MoreDetails>
            <S.Details>
              <img src={PeopleCountIcon} alt="peopleCountIcon" title="peopleCountIcon" />
              {theaterDetails?.maxPerson <= 4 && theaterDetails?.code != 'RN_CT' ? (
                <> Extra people not allowed</>
              ) : (
                <>
                  &#8377; {theaterDetails?.costPerExtraPerson}/extra person after {theaterDetails?.avgPerson} people
                </>
              )}
            </S.Details>
            <S.Details>
              <S.MoreDtail>
                <img src={TheaterInchIcon} alt="theaterInchIcon" title="theaterInchIcon" />
                {theaterDetails?.screenSize}
              </S.MoreDtail>
              <S.MoreDtail>
                <img src={TheaterWIcon} alt="theaterWIcon" title="theaterWIcon" />
                {theaterDetails?.speakerSize}
              </S.MoreDtail>
            </S.Details>
            {!isMweb &&
              <>
                <S.Details>
                  <img src={Cake} alt="Cake" title="Cake" />
                  Add cakes and gifts in next step
                </S.Details>
                <S.Details>
                  <img src={FoodAndBeverages} alt="FoodAndBeverages" title="FoodAndBeverages" />
                  Food can be ordered at theater
                </S.Details>
                <S.Details>
                  <img src={Refund} alt="Refund" title="Refund" />
                  Free cancellation upto before 72 hrs of slot
                </S.Details>
              </>
            }
          </S.MoreDetails>*/}
        </S.RightView>
      </S.TheaterView>
      {/*<S.FooterView>
        {isMweb &&
          <S.MoreDetails>
            <S.Details>
              <img src={Cake} alt="Cake" title="Cake" />
              Add cakes and gifts in next step
            </S.Details>
            <S.Details>
              <img src={FoodAndBeverages} alt="FoodAndBeverages" title="FoodAndBeverages" />
              Food can be ordered at theater
            </S.Details>
            <S.Details>
              <img src={Refund} alt="Refund" title="Refund" />
              Free cancellation upto before 72 hrs of slot
            </S.Details>
          </S.MoreDetails>
        }
        <S.TimeSlots>
          <S.TimeSlotLabel>Choose a time slot:</S.TimeSlotLabel>
          <S.TimeSlotsAvailable>
            {times.length > 0 &&
              times.map((time: any, ind: number) => {
                const isDisabled = disabledTheaterTimeSlots?.includes(ind);
                return (
                  <React.Fragment key={`time_slot${ind} `}>
                    {isDisabled ? (
                      <DisableTimeSlot key={ind} disabled={isDisabled}>
                        <TimeIcon $active={activeTimeSlotInd[index] == ind}>
                          {getTimeIcon(time) === "SunIcon" ? (
                            <img src={SunIcon} alt="sunIcon" title="sunIcon" />
                          ) : (
                            <img src={MoonIcon} alt="moonIcon" title="moonIcon" />
                          )}
                        </TimeIcon>

                        <S.TimeTxt>{time}</S.TimeTxt>
                      </DisableTimeSlot>
                    ) : (
                      <TimeSlot
                        key={ind}
                        onClick={() => handleSelectedTimeSlot(time, ind)}
                        $active={activeTimeSlotInd[index] == ind}
                      >
                        <TimeIcon $active={activeTimeSlotInd[index] == ind}>
                          {getTimeIcon(time) === "SunIcon" ? (
                            <img src={SunIcon} alt="sunIcon" title="sunIcon" />
                          ) : (
                            <img src={MoonIcon} alt="moonIcon" title="moonIcon" />
                          )}
                        </TimeIcon>

                        <S.TimeTxt>{time}</S.TimeTxt>
                      </TimeSlot>
                    )}
                  </React.Fragment>
                );
              })}
          </S.TimeSlotsAvailable>
        </S.TimeSlots>*/}
      <S.MwebTimeSlotsAvailable>
        <S.MwebTimeSlotLabel>Choose a time slot:</S.MwebTimeSlotLabel>
        <Select
          placeholder="Select here"
          isSearchable={false}
          options={timeOptions}
          onChange={handleMwebSelectedTimeSlot}
          styles={{
            menu: (styles) => ({
              zIndex: 3,
              borderBotom: "1px solid #cacaca",
              borderRadius: "0.75rem",
            }),
            menuList: (styles) => ({
              ...styles,
              backgroundColor: "#edecea",
              borderRadius: "0.75rem",
            }),
            control: (styles) => ({
              ...styles,
              backgroundColor: "#ffffff",
              flex: 1,
              padding: "0.5rem 0",
              margin: "0.7rem 0",
              borderRadius: "0.75rem",
              borderColor: "#352e2e",
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                // ...styles,
                backgroundColor: "#edecea",
                padding: "0.5rem",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #cacaca",
              };
            },
          }}
        />
      </S.MwebTimeSlotsAvailable>

      {/*} {((!isMweb || (isMweb && !(activeTimeSlotInd.length === 0 || activeTimeSlotInd[index] === -1)))) &&
          (<S.BookingAction>
            <ButtonView
              text="Proceed"
              disabled={
                (!isMweb && (activeTimeSlotInd.length === 0 || activeTimeSlotInd[index] === -1)) ||
                remainingSlots === 0 ||
                (isMweb && (activeTimeSlotInd.length === 0 || activeTimeSlotInd[index] === -1))
              }
              onClick={handleProceed}
              styles={{ width: isMweb ? "100%" : "" }}
            />
            <S.TipContainer>
              (Just pay <b>&#8377;{theaterDetails?.advance} advance</b> to book)
            </S.TipContainer>
          </S.BookingAction>)}
      </S.FooterView>*/}
    </S.TheatersContainer>
  );
};

export default Theaters;
