import * as S from "./FAQSection.style";
import { ReactComponent as MinusIcon } from "../../../../assets/images/MinusIcon.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/images/PlusIcon.svg";
import { FAQsList } from "../../../Pages/FAQs/FAQsList";
import { useState } from "react";
import { ReactComponent as ArrowIcon } from "../../../../assets/images/ArrowIcon.svg";
import { useNavigate } from "react-router-dom";
import { ButtonContainer } from "../Header/Header.style";
import ButtonView from "../../../../components/common/ButtonView";

const FAQSection = ({ fromCityPage = false }: { fromCityPage?: boolean }) => {
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const navigate = useNavigate(); // Track which question is expanded

  // Extract the required questions
  const topFirstQuestion = FAQsList[0]; // First question from the top
  const topThirdQuestion = FAQsList[2]; // Third question from the top
  const bottomThirdQuestion = FAQsList[FAQsList.length - 3]; // Third question from the bottom
  const bottomFirstQuestion = FAQsList[FAQsList.length - 1]; // First question from the bottom

  // List of filtered questions
  const filteredQuestions = [
    topFirstQuestion,
    topThirdQuestion,
    bottomThirdQuestion,
    bottomFirstQuestion,
  ];

  // Toggle the expanded state for a particular question
  const toggleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? -1 : index); // Collapse if the same question is clicked again
  };
  const handleViewAllClick = () => {
    navigate("/faqs");
  };

  const handleBookNow = () => {
    //navigate("private-theaters-booking");
    const element = document.getElementById("our-branches");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the section
    }
  };

  return (
    <>
      <S.TitleContainer
        style={{ ...(fromCityPage && { backgroundColor: "rgb(255 238 240)" }) }}
      >
        <S.Heading>Frequently Asked Questions</S.Heading>
        <S.SubHeading>
          Have doubts? Check out our FAQs. Please feel free to reach out on
          86189 76974 for more queries
        </S.SubHeading>
      </S.TitleContainer>

      <S.QuestionAnswerContent
        style={{ ...(fromCityPage && { backgroundColor: "rgb(255 238 240)" }) }}
      >
        {filteredQuestions.map((item, index) => (
          <>
            <S.QuestionAnswerContainer key={index}>
              <S.QuestionAndAnswer>
                <S.Question onClick={() => toggleExpand(index)}>
                  {item.summary}
                </S.Question>

                {/* Show the answer only if the question is expanded */}
                {expandedIndex === index && <S.Answer>{item.details}</S.Answer>}
              </S.QuestionAndAnswer>

              {/* Toggle between Plus and Minus icons based on the expanded state */}
              <S.ExpandCollapseIcon onClick={() => toggleExpand(index)}>
                {expandedIndex === index ? <MinusIcon /> : <PlusIcon />}
              </S.ExpandCollapseIcon>
            </S.QuestionAnswerContainer>

            <S.GreyLine />
          </>
        ))}
        {fromCityPage ? (
          <ButtonContainer style={{ paddingBottom: "20px" }}>
            <div
              onClick={handleBookNow}
              title="City Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
              style={{ textDecoration: "none", color: "#ffffff" }}
            >
              <ButtonView text={"Book Now"} />
            </div>
          </ButtonContainer>
        ) : (
          <S.ViewAllButton onClick={handleViewAllClick}>
            <S.ViewAllText>View All Questions</S.ViewAllText>
            <ArrowIcon />
          </S.ViewAllButton>
        )}
      </S.QuestionAnswerContent>
    </>
  );
};

export default FAQSection;
