import * as React from "react";
import styled from "styled-components";

import { useState, useRef } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TheBingeTownLogo from "../../../../assets/images/TheBingeTownLogo.svg";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ButtonView from "../../../../components/common/ButtonView";
import { GiHamburgerMenu } from "react-icons/gi";
import * as S from "./Header.style";

export const MenuItemContainer = styled.div<{ $active?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${(props) => (props.$active ? "#34255C" : "#ffffff")};
  color: ${(props) => (props.$active ? "#ffffff" : "#000000")};
  padding: ${(props) => (props.$active ? "0.5rem" : "")};
  border-radius: ${(props) => (props.$active ? "24px" : "")};
`;

const Header = ({ bookFromCity = false }: { bookFromCity?: boolean }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorLearnEl, setAnchorLearnEl] = React.useState<null | HTMLElement>(
    null
  );
  const [isLearnActive, setIsLearnActive] = useState(false);
  const wrapperRef = useRef<HTMLInputElement>(null);
  const open = Boolean(anchorEl);
  const learnOpen = Boolean(anchorLearnEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!open) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  const handleLearnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!learnOpen) setAnchorLearnEl(event.currentTarget);
    else setAnchorLearnEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLearnClose = () => {
    setAnchorLearnEl(null);
  };

  const handleLearnMore = () => {
    setIsLearnActive(!isLearnActive);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { hash, pathname, search } = location;

  const navLinkStyles = ({ isActive }: any) => {
    return {
      textDecoration: "none",
      color: isActive ? "#ffffff" : "#000000",
      backgroundColor: isActive ? "#34265c" : "#ffffff",
      padding: isActive ? "0.2rem 1rem" : "",
      borderRadius: isActive ? "24px" : "",
    };
  };

  const handleBookNow = () => {
    //navigate("private-theaters-booking");
    const element = document.getElementById("our-branches");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the section
    }
  };

  return (
    <S.HeaderContainer>
      <S.LogoContainer>
        <Link
          to="/"
          title="Home Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
        >
          <img
            src={TheBingeTownLogo}
            alt="TheBingeTown"
            title="TheBingeTown"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Link>
      </S.LogoContainer>
      <S.HeaderOptions>
        <div>
          <NavLink
            to="/our_services"
            title="Our Services | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={navLinkStyles}
          >
            Our Services
          </NavLink>
        </div>
        <div>
          <NavLink
            to="/about_us"
            title="About Us | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={navLinkStyles}
          >
            About Us
          </NavLink>
        </div>
        <div>
          <NavLink
            to="/login"
            title="Manage My Bookings | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={navLinkStyles}
          >
            My Bookings
          </NavLink>
        </div>
        <div>
          <NavLink
            to="/gallery"
            title="Gallery | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={navLinkStyles}
          >
            Gallery
          </NavLink>
        </div>
        <div>
          <NavLink
            to="/contact_us"
            title="Contact Us | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={navLinkStyles}
          >
            Contact Us
          </NavLink>
        </div>
        <div>
          <NavLink
            to="/join_waitlist"
            title="Join Waitlist | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={navLinkStyles}
          >
            Join Waitlist
          </NavLink>
        </div>

        <S.LearnContainer onClick={handleLearnClick}>
          Learn
          <S.LearnDownArrowIcon>
            {anchorLearnEl ? (
              <IoIosArrowUp
                size={24}
                style={{ position: "relative", top: "-2px" }}
              />
            ) : (
              <IoIosArrowDown
                size={24}
                style={{ position: "relative", top: "-2px" }}
              />
            )}
          </S.LearnDownArrowIcon>
        </S.LearnContainer>

        <Menu
          id="menu-appbar"
          anchorEl={anchorLearnEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={learnOpen}
          onClose={handleLearnClose}
          style={{
            padding: "1rem 2rem",
            width: "14rem",
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          <NavLink
            to="/blogs"
            title="Blogs | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "#000000" }}
          >
            <MenuItem
              onClick={handleLearnClose}
              style={{
                width: "9rem",
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Blogs
            </MenuItem>
          </NavLink>
          <NavLink
            to="/faqs"
            title="Faqs | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "#000000" }}
          >
            <MenuItem
              onClick={handleLearnClose}
              style={{
                width: "9rem",
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              FAQs
            </MenuItem>
          </NavLink>
          <NavLink
            to="/refund_policy"
            title="Refund Policy | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "#000000" }}
          >
            <MenuItem
              onClick={handleLearnClose}
              style={{
                width: "9rem",
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Refund Policy
            </MenuItem>
          </NavLink>
          <NavLink
            to="/pricing"
            title="Pricing | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "#000000" }}
          >
            <MenuItem
              onClick={handleLearnClose}
              style={{
                width: "9rem",
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Pricing
            </MenuItem>
          </NavLink>
        </Menu>
      </S.HeaderOptions>

      <S.ButtonContainer>
        {bookFromCity ? (
          <button
            // to="/"
            onClick={handleBookNow}
            title="City Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{
              textDecoration: "none",
              color: "#ffffff",
              border: "none",
              outline: "none",
              background: "none",
            }}
          >
            <ButtonView text={"Book Now"} />
          </button>
        ) : (
          <Link
            to="/"
            title="City Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "#ffffff" }}
          >
            <ButtonView text={"Book Now"} />
          </Link>
        )}
      </S.ButtonContainer>
      <S.MenuContainer onClick={handleClick}>
        <GiHamburgerMenu size={24} />
      </S.MenuContainer>
      <S.MenuView style={{ display: open ? "flex" : "none" }} ref={wrapperRef}>
        <MenuItemContainer onClick={handleClose}>
          <Link
            to="/our_services"
            title="Our Services | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "black" }}
          >
            Our services
          </Link>
        </MenuItemContainer>
        <MenuItemContainer onClick={handleClose}>
          <Link
            to="/about_us"
            title="About Us | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "black" }}
          >
            About Us
          </Link>
        </MenuItemContainer>
        <MenuItemContainer onClick={handleClose}>
          <Link
            to="/login"
            title="Manage My Bookings | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "black" }}
          >
            My Bookings
          </Link>
        </MenuItemContainer>
        <MenuItemContainer onClick={handleClose}>
          <Link
            to="/gallery"
            title="Gallery | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "black" }}
          >
            Gallery
          </Link>
        </MenuItemContainer>
        <MenuItemContainer onClick={handleClose}>
          <Link
            to="/contact_us"
            title="contact_us"
            style={{ textDecoration: "none", color: "#000000" }}
          >
            Contact Us
          </Link>
        </MenuItemContainer>
        <MenuItemContainer onClick={handleClose}>
          <Link
            to="/join_waitlist"
            title="Join Waitlist | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "black" }}
          >
            Join Waitlist
          </Link>
        </MenuItemContainer>
        <MenuItemContainer $active={isLearnActive}>
          <S.LearnTxt>Learn</S.LearnTxt>
          <S.LearnDownArrowIcon onClick={handleLearnMore}>
            {isLearnActive ? (
              <IoIosArrowUp size={24} />
            ) : (
              <IoIosArrowDown size={24} />
            )}
          </S.LearnDownArrowIcon>
        </MenuItemContainer>
        {isLearnActive ? (
          <S.LearnMoreOptions>
            <MenuItemContainer onClick={handleClose}>
              <Link
                to="/faqs"
                title="Faqs | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "black" }}
              >
                FAQs
              </Link>
            </MenuItemContainer>
            <MenuItemContainer onClick={handleClose}>
              <Link
                to="/blogs"
                title="Blogs | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "black" }}
              >
                Blogs
              </Link>
            </MenuItemContainer>
            <MenuItemContainer onClick={handleClose}>
              <Link
                to="/refund_policy"
                title="Refund Policy | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "black" }}
              >
                Refund Policy
              </Link>
            </MenuItemContainer>
            <MenuItemContainer onClick={handleClose}>
              <Link
                to="/pricing"
                title="Pricing | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "black" }}
              >
                Pricing
              </Link>
            </MenuItemContainer>
          </S.LearnMoreOptions>
        ) : null}
      </S.MenuView>
    </S.HeaderContainer>
  );
};
export default Header;
