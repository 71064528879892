import { FaLongArrowAltRight } from "react-icons/fa";
import {
  Address,
  CardContainer,
  ImageWrapper,
  MapButton,
  Title,
} from "./BranchCard.styles";
import { SiGooglemaps } from "react-icons/si";

const BranchCard = ({
  mapLink = "/",
  name,
  theatre = 1,
  link = "/",
  image = "",
  address,
}: any) => {
  return (
    <CardContainer>
      <ImageWrapper target="_blank" rel="noopener noreferrer" href={link}>
        <img
          src={image} // Replace with your image URL
          alt="Theatre"
        />

        <div className="overlay">
          <p className="theatre_count">{theatre} Theatres</p>
          <p className="click-button">
            <span>Click Here</span>
            <FaLongArrowAltRight color="#fff" />
          </p>
        </div>
      </ImageWrapper>
      <Title>{name}</Title>
      <Address>{address}</Address>
      <MapButton href={mapLink} target="_blank" rel="noopener noreferrer">
        <SiGooglemaps />
        <span>See on map</span>
      </MapButton>
    </CardContainer>
  );
};

export default BranchCard;
