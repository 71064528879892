import styled from "styled-components";

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #fff;
  padding: 2rem 90px;

  // laptop
  @media only screen and (max-width: 1020px) {
    padding: 6rem 2rem;
  }
`;

export const MwebGalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #34255c;
  padding: 2rem 1.5rem;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1020px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

export const Image = styled.a`
  height: 200px;
  width: 100%;
`;

export const BookNowBtnContainer = styled.div`
  padding-top: 4rem;
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 786px) {
    padding: 1rem 0rem;
    flex: 1;
  }
`;

export const BookNowBtn = styled.div`
  display: flex;
  justify-content: center;
  background-color: #34265c;
  color: white;
  width: 12rem;
  height: 3rem;
  border-radius: 64px;
  align-items: center;

  @media only screen and (max-width: 786px) {
    flex: 1;
  }
`;

export const Heading = styled.h2`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;
  color: #000;
  line-height: 49px;
  font-family: "DM Sans", sans-serif;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const MwebPhotosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const MwebPhotoContainer_1 = styled.div`
  height: 12.5rem;
  background-color: white;
  border-radius: 16px;
`;

export const MwebPhotoContainer_2 = styled.div`
  height: 11.5rem;
  background-color: white;
  border-radius: 16px;
`;

export const MwebRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const MwebPhotoContainer_3 = styled.div`
  height: 11.5rem;
  background-color: white;
  border-radius: 16px;
  flex: 1;
`;

export const MwebPhotoContainer_4 = styled.div`
  height: 11.5rem;
  background-color: white;
  border-radius: 16px;
  flex: 1;
`;

// ----

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: black;
  flex-shrink: 1;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const PhotoContainer_1 = styled.div`
  width: 29rem;
  height: 15.5rem;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
`;

export const PhotoContainer_2 = styled.div`
  width: 22.5rem;
  height: 24rem;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
`;

export const PhotoContainer_3 = styled.div`
  width: 16.5rem;
  height: 15.5rem;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
`;

export const PhotoContainer_4 = styled.div`
  width: 29rem;
  height: 24rem;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  flex-shrink: 1;
  display: flex;
`;

export const PhotoContainer_5 = styled.div`
  width: 22rem;
  height: 15.5rem;
  background-color: white;
  border-radius: 16px;
  // flex: 1;
`;

export const PhotoContainer_6 = styled.div`
  width: 16.5rem;
  height: 24rem;
  background-color: white;
  border-radius: 16px;
  // flex: 1;
`;

export const ViewAllPictures = styled.div`
  flex: 3;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 18px;
  align-items: flex-end;
  color: #ffffff;
  text-decoration: underline;
  gap: 0.5rem;
  margin: 1.5rem 0;
  font-family: "DM Sans", sans-serif;
`;

export const MwebAllServicesTxt = styled.div`
  display: flex;
`;

export const MwebAllServicesIcon = styled.div`
  display: flex;
`;
