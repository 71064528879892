import styled from "styled-components";

export const CardContainer = styled.div`
  background-color: #f9ecff;
  border-radius: 16px;
  padding: 20px;
  font-family: Arial, sans-serif;
  height: 100%;

  @media (max-width: 1020px) {
    width: 100%;
  }
`;

export const ImageWrapper = styled.a`
  position: relative;
  border-radius: 12px;
  height: 400px;
  display: block;
  overflow: hidden;

  img {
    width: 100%;
    border-radius: 12px;
    object-fit: cover;
    height: 100%;
  }

  .overlay {
    position: absolute;
    bottom: 20px;
    left: 10px;
  }

  .theatre_count {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 6px 12px;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .click-button {
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    font-weight: bold;
  }

  @media (max-width: 1020px) {
    height: 250px;
  }
`;

export const Title = styled.h2`
  margin: 16px 0 8px;
  font-size: 24px;
  color: #000;
`;

export const Address = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 16px;
`;

export const MapButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  max-width: 150px;
  padding: 8px 16px;
  background-color: #fff;
  color: #4b0082;
  text-decoration: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c084ff;
  }
`;
