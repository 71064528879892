import styled from "styled-components";
import ButtonView from "../../../../../components/common/ButtonView";
import { MdOutlineTouchApp } from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { LuPartyPopper } from "react-icons/lu";
import { GiConfirmed } from "react-icons/gi";

const HowItWorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 3rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2rem 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
`;

const Heading = styled.h2`
  flex: 1;
  font-weight: 600;
  font-size: 32px;
  padding-bottom: 2rem;
  font-family: "DM Sans", sans-serif;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around; /* Space items evenly */
  align-items: center; /* Center align items vertically */
  padding: 20px; /* Add some spacing */
  background-color: #f8f8f8; /* Light gray background */
  flex-wrap: wrap;

  @media (max-width: 1020px) {
  }
`;

const Step = styled.div`
  text-align: center; /* Center align text inside each step */
  max-width: 200px; /* Optional: Limit the width of each step */
`;

const Icon = styled.div`
  font-size: 72px; /* Size of the icon */
  color: #000000; /* Black color for icons */
  margin-bottom: 10px; /* Space below the icon */
`;

const Title = styled.p`
  font-size: 24px; /* Font size */
  color: #6c27de; /* Purple color for the text */
  font-weight: bold; /* Bold text */
  line-height: 1.5; /* Line height for readability */
`;

const HowItWorks = () => {
  const handleBookNow = () => {
    //navigate("private-theaters-booking");
    const element = document.getElementById("our-branches");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the section
    }
  };

  return (
    <>
      <HowItWorksContainer>
        <Heading>How to Book Your Private Theater?</Heading>
        <Container>
          {/* Step 1 */}
          <Step>
            <Icon>
              <MdOutlineTouchApp />
            </Icon>
            {/* Replace with an actual icon or image */}
            <Title>Choose Your Preferred Branch</Title>
          </Step>

          {/* Step 2 */}
          <Step>
            <Icon>
              <BsCalendarDate />
            </Icon>
            <Title>Select a Date and Time.</Title>
          </Step>

          {/* Step 3 */}
          <Step>
            <Icon>
              <LuPartyPopper />
            </Icon>
            <Title>Customise Your Celebration.</Title>
          </Step>

          {/* Step 4 */}
          <Step>
            <Icon>
              <GiConfirmed />
            </Icon>
            <Title>Confirm Your Booking.</Title>
          </Step>
        </Container>
        <ButtonContainer>
          <div
            onClick={handleBookNow}
            title="City Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
            style={{ textDecoration: "none", color: "#ffffff" }}
          >
            <ButtonView text={"Book Now"} />
          </div>
        </ButtonContainer>
      </HowItWorksContainer>
    </>
  );
};
export default HowItWorks;
