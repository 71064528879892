import BranchCard from "./Components/BranchCard";
import { OurBranchesContainer } from "./OurBranches.styles";

const OurBranches = ({ cityJson }: any) => {
  return (
    <OurBranchesContainer id="our-branches">
      <h2 className="heading">Our Branches in {cityJson.name}</h2>
      <div className="cards_container">
        {cityJson.branches.map((branch: any) => (
          <BranchCard
            link={branch.theatreLink}
            name={branch.name}
            image={branch.image}
            mapLink={branch.mapLink}
            address={branch.address}
            theatre={branch.theatres}
          />
        ))}
      </div>
    </OurBranchesContainer>
  );
};

export default OurBranches;
